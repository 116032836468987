<template>
    <div class="consulta-reporte" >
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
        <div class="page-wrapper chiller-theme toggled">
            <CRow>
                <CCol sm="3" class="sinpading" style="padding:0px;">
                    <nav class="sidebar-wrapper" style="background: #ffffff; border: 1px solid #cdcbcb; border-top: none; border-bottom: none">
                        <div class="sidebar-content" style="overflow: none; border: none">
                            <div class="sidebar-brand bg-plan" style="padding: 10px 30px;">
                                <h6 href="#" class="mb-0">{{ $t('label.reportType') }}</h6>
                            </div>
                            <!--div class="sidebar-search">
                                <div>style="padding: 10px 30px;"
                                    <div class="input-group">
                                        <input type="text" class="form-control search-menu" placeholder="Buscar...">
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div-->
                            <div class="sidebar-menu">
                                <ul>
                                    <li class="sidebar-dropdown">
                                        <a href="#" @click="getReportType(1)">
                                            <span>{{ $t('label.strategicAlliance') }}</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-dropdown">
                                        <a href="#" @click="getReportType(2)">
                                            <span>{{ $t('label.vesselsServed') }}</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-dropdown">
                                        <a href="#" @click="getReportType(3)">
                                            <span>{{ $t('label.vesselsServedGeneralList') }}</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-dropdown">
                                        <a href="#" @click="getReportType(4)">
                                            <span>{{ $t('label.visitsByVessel') }}</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-dropdown">
                                        <a href="#" @click="getReportType(5)">
                                            <span>{{ $t('label.inventoriesOfContainersInThePort') }}</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-dropdown">
                                        <a href="#" @click="getReportType(6)">
                                            <span>{{ $t('label.eventGeneralList') }}</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-dropdown">
                                        <a href="#" @click="getReportType(7)">
                                            <span>{{ $t('label.inspectionGeneralList') }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </CCol>
                <CCol sm="9" class="sinpading" style="padding:0px;">
                    <main class="page-content" style="background: #ffffff; height: 100%!important;">
                        <div class="wrapper">
                            <div class="page-title bg-watch" style="min-height: 36.8px">
                                <h6 class="mb-0">{{ getNameReport }}</h6>
                            </div>
                            <div class="container">
                                <CRow >
                                    <label class="col-sm-12 col-lg-2 text-right mt-2">{{$t('label.Description')}}</label>
                                    <CCol sm="12" lg="8">
                                        <CInput
                                            disabled
                                            v-uppercase
                                            required 
                                            :value="getNameReport"
                                        >
                                        </CInput>
                                    </CCol>
                                    <CCol sm="2" lg="2" class="text-left">
                                        <CButton
                                            color="excel"
                                            size="sm"
                                            class="ml-2"
                                            @click="exportExcel"
                                        >
                                            <CIcon name="cil-file-excel"/>&nbsp; XSLX
                                        </CButton>
                                    </CCol>
                                </CRow> 
                                <CRow>
                                    <label class="col-sm-12 col-lg-2 text-right mt-2">{{$t('label.date')}}</label>
                                    <CCol sm="12" lg="3" class="center-field">
                                        <vue-datepicker 
                                            type="datetime"
                                            header
                                            :lang="this.$i18n.locale"
                                            :editable="false"
                                            :clearable="false"
                                            format="DD/MM/YYYY HH:mm"
                                            placeholder="DD/MM/YYYY HH:mm"
                                            time-title-format="DD/MM/YYYY HH:mm"
                                            v-model="searchFilterDate.fromDay"
                                            :disabled-date="validateDateRange"
                                            :append-to-body="false"
                                            value-type="format"
                                            :show-second="false"
                                        >
                                        <template #icon-calendar>
                                            <div style="display: none"></div>
                                        </template>
                                    </vue-datepicker>
                                    </CCol>
                                    <label class="col-sm-12 col-lg-auto text-right mt-2">-</label>
                                    <CCol sm="11" lg="3" class="center-field mb-2">
                                        <vue-datepicker 
                                            type="datetime"
                                            header
                                            :lang="this.$i18n.locale"
                                            :editable="false"
                                            :clearable="false"
                                            format="DD/MM/YYYY HH:mm"
                                            placeholder="DD/MM/YYYY HH:mm"
                                            time-title-format="DD/MM/YYYY HH:mm"
                                            v-model="searchFilterDate.toDay"
                                            :disabled-date="validateDateRange"
                                            :append-to-body="false"
                                            value-type="format"
                                            :show-second="false"
                                        >
                                        <template #icon-calendar>
                                            <div style="display: none"></div>
                                        </template>
                                    </vue-datepicker>
                                    </CCol>
                                    <CCol class="col-sm-12 col-lg-auto center-field mr-0" style="text-align: right;">
                                        <CButton
                                            color="wipe"
                                            class="justify-content-end"
                                            size="sm"
                                            v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                                            @click="refreshComponent()"
                                            >
                                            <CIcon name="cil-brush-alt" />
                                        </CButton>
                                    </CCol>
                                    <!--label v-if="reportType == 3" class="required text-right col-sm-12 col-lg-2 col-form-label">{{ $t('label.date') }}</label>
                                    <CCol v-if="reportType == 3" sm="12" lg="5" class="pr-0">
                                        <vue-datepicker
                                            v-model="rangeDate"
                                            type="datetime"
                                            range
                                            placeholder="DD/MM/YYYY HH:mm DD/MM/YYYY HH:mm"
                                            format="DD/MM/YYYY HH:mm"
                                            time-title-format="DD/MM/YYYY HH:mm"
                                            value-type="format" 
                                            :show-second="false" 
                                            :clearable="false"
                                            :editable="false"
                                            :lang="$i18n.locale"  
                                            :disabled-date="validateDateRange"     
                                            @focus="rightDate($event)"    
                                            class="w-100 float-left vue-datepicker-callendar-double"    
                                            style="border-top-right-radius: 1px; border-bottom-right-radius: 1px;"                  
                                        >
                                            <template #icon-calendar>
                                                <div style="display: none"></div>
                                            </template>
                                        </vue-datepicker>
                                    </CCol>
                                    <CCol sm="12" lg="1" class="pl-0" v-if="reportType == 3">
                                        <CButton
                                            size="sm"
                                            class="btn btn-light"
                                            id="button-callendar-double"
                                        >
                                        <CIcon name="cil-calendar" />
                                        </CButton>
                                    </CCol-->
                                </CRow>
                                <CRow v-if="reportType == 5 || reportType == 6 || reportType == 7">
                                    <label class="col-sm-12 col-lg-2 text-right mt-3">{{$t('label.yard')}}</label>
                                    <CCol sm="12" lg="8" class="center-field mt-2">
                                        <CSelect
                                            :placeholder="$t('label.select')"
                                            :options="yardOptions"
                                            :value.sync="yardId"
                                            @change="listVisitList($event)"
                                        >
                                        </CSelect>
                                        <loading v-show="loadingVessels" element="select" />
                                    </CCol>
                                </CRow>
                                <CRow v-if="reportType == 5 || reportType == 7 ">
                                    <label class="col-sm-12 col-lg-2 text-right mt-3">{{$t('label.visit')}}</label>
                                    <CCol sm="12" lg="8" class="center-field mt-2">
                                        <CSelect
                                            :placeholder="$t('label.select')"
                                            :options="visitOptions"
                                            :value.sync="visitId"
                                            :disabled="isEmpty(VisitList)"
                                        >
                                        </CSelect>
                                        <loading v-show="loadingVessels" element="select" />
                                    </CCol>
                                </CRow>
                                <CRow v-if="reportType == 4">
                                    <label class="col-sm-12 col-lg-2 text-right mt-2">{{$t('label.vessel')}}</label>
                                    <CCol sm="12" lg="8" class="center-field">
                                        <CSelect
                                            :placeholder="$t('label.select')"
                                            :options="vesselOptions"
                                            :disabled="isEmpty(vessels)"
                                            :value.sync="vesselId"
                                        >
                                        </CSelect>
                                        <loading v-show="loadingVessels" element="select" />
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </main>
                </CCol>
            </CRow>
        </div>
        
    </div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';
import 'jspdf-autotable';
import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import mixinServicio from '@/_mixins/servicio';
import GeneralMixin from '@/_mixins/general';
import YardManagement from '@/_mixins/yardManagement';

function data() {
  return {
    items: [],
    nameReport: '',
    reportType: 0,
    previousDate: new Date("2010-01-01"),
    laterDate: new Date(),
    rangeDate: [],
    loadingOverlay: false,
    searchFilterDate:{
        fromDay:"",
        toDay:"",
    },
    vesselId: '',
    vessels: [],
    yardId: '',
    yards: [],
    loadingVessels: false,
    YardCargoGeneralList: [],
    VisitName: {},
    YardName: {},
    visitId: '',
    VisitList: []
  };
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

const nameMonthList ={   es:     ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO",
                                "JUNIO","JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
                        en:     ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY',
                                'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'],
                    }
async function alianza () {
    this.loadingOverlay = true;
    let dateStart = '';
    let dateEnd = '';

    dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
    dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

    await this.$http.get("VisitStrategicAllianceReport", { DateStart:dateStart, DateEnd:dateEnd, CompanyBranchId: this.CompanyBranchId })
    .then(response => {
        let listado = [...response.data.data];
        
        let rowData = [];
        
        //CUADRO 1
        listado.forEach(x => {
        rowData.push({
            No: x.IdX,
            BUQUE: x.VesselName,
            VIAJE: x.Voyage,
            AGENCIA: x.Agency,
            ESTIBA: x.Stevedore,  
            MUELLE: x.BerthName,  
            ATRAQUE: x.Ata,   
            HORA: x.AtaHour,  
            INICIO_OPS: x.BeginningOperations,  
            HORA_: x.BeginningOperationsHour,
            FULL: x.DischargeFull,
            MTY: x.DischargeEmpty,
            FULL_: x.LoadFull,
            MTY_: x.LoadEmpty,
            TERMINO_OPS: x.EndOperations,
            HORA_T: x.EndOperationsHour,
            DESATRAQUE: x.Atd,
            HORA_D: x.AtdHour,
        });
        });

        if (rowData.length > 0){ 
            this.getStrategicAllianceReportExcel(rowData,this.$t('label.vesselsServedIn') +' '+this.searchFilterDate.fromDay+' - '+this.searchFilterDate.toDay);
        } else {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailble'),
                type: 'error',
            });
        }
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
    });
    this.loadingOverlay = false;
}

async function getStrategicAllianceReportExcel(rowData, titulo) {
    this.loadingOverlay = true;

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.$t('label.strategicAlliance'));
    const documento = this.$t('label.strategicAlliance').toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    //INFO BUQUE
  
    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    columnsDefs = Object.keys(rowData[0]);
    keyColumns = columnsDefs;
    y = 18;
    bandera = false;

    if (y < 10) letra = 8;
    worksheet.mergeCells('A1:B1');
    const response1 = await this.$http.getFile('base64', {
        path: this.user.LogoRoute.replace('Public/', '', null, 'i')
    });

    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
    const imageId = workbook.addImage({
        base64: myBase64Image,
        extension: divisiones1[1],
    });
    worksheet.addImage(imageId, 'A1:B1');
    worksheet.getCell("A1").border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    const rowIndex1 = worksheet.getRow(1);
    rowIndex1.height = 100;

    worksheet.mergeCells("C1:" + numeroLetra(y) + "1");

    const customCell = worksheet.getCell("C1");
    const row = worksheet.getRow(1);
    row.height = 60;
    customCell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell.note = {
        margins: {
            insetmode: 'auto',
            inset: [10, 10, 5, 5]
        }
    }
    customCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: 'f2f2f2'
        },
    }
    customCell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }    
    customCell.value = "ALIANZA ESTRATEGICA COMERCIAL CON BOLIVARIANA DE PUERTOS S.A.";
    worksheet.addRow();

    worksheet.mergeCells("A2:R2");
    const customCell1 = worksheet.getCell("A2");
    customCell1.height = 30;
    customCell1.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell1.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCell1.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell1.value = titulo;

    const hoy = new Date().toLocaleString();
    worksheet.mergeCells("A3:I3");
    worksheet.mergeCells("J3:R3");
    const customCell2 = worksheet.getCell("A3");
    customCell2.height = 50;
    customCell2.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell2.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell2.value = this.$t('label.printed')+': '+hoy;
    
    const customCell4 = worksheet.getCell("J3");
    customCell4.height = 50;
    customCell4.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true
    };
    customCell4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    customCell4.value = this.$t('label.user')+': ' + this.user.UserName + " " + this.user.UserLastName;
    worksheet.mergeCells("A4:R4");
    
    worksheet.mergeCells("A5:J5");
    let ccell = worksheet.getCell("A5");
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.mergeCells("K5:L5");
    ccell = worksheet.getCell("K5");
    ccell.height = 50;
    ccell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        }
    };
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    ccell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    ccell.value = "DESCARGA";

    worksheet.mergeCells("M5:N5");
    ccell = worksheet.getCell("N5");
    ccell.height = 50;
    ccell.font = {
        name: "Calibri",
        family: 4,
        size: letra,
        underline: false,
        bold: true,
        color: {
          argb: 'ffffff'
        }
    };
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
    ccell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    ccell.value = "CARGA";
    
    worksheet.mergeCells("O5:R5");
    ccell = worksheet.getCell("O5");
    ccell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    ccell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };
    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    //CUADRO 1
    let i = 0;
    columnsDefs.map(function (data) {
      let valor = bandera ? data.headerName.length : data.length;
      let long = 10;
      if ((valor >= 5) && (valor <= 12)) {
          long = 20;
      } else if ((valor >= 11) && (valor <= 18)) {
          long = 25;
      } else if ((valor >= 19) && (valor <= 26)) {
          long = 30;
      } else if (valor >= 27) {
          long = 40;
      }

      worksheet.getColumn(i + 1).width = long;
      let cell = headerRow.getCell(i + 1);
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '808080'
          }
      }
      cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
      }
      cell.value = data.replace("_"," ");
      cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      i++;
    })

    rowData.map(async function (data) {
      let dataRow = worksheet.addRow();
      let indice = 0;
      keyColumns.map(function (data2) {
          let cell = dataRow.getCell(indice + 1);
          cell.value = data[data2];
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        };
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
          indice++;
      })
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
    .then(buffer => {
        saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
        this.$notify({
            group: 'container',
            text: this.$t('label.reportSuccessfully'),
            type: "success"
        });
    })
    this.loadingOverlay = false;

}

async function atendidos () {
    this.loadingOverlay = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
    dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

  await this.$http.get("VisitVesselsServicedReport", { DateStart:dateStart, DateEnd:dateEnd, CompanyBranchId: this.CompanyBranchId })
  .then(response => {
    let listado = [...response.data.data];
    let bandera = true;
    
    let cuadro = [];
    let rowData = [];
    let arrtotales = [1,0,0,0,0,0,0];
    let idx = 1;
    //CUADRO 1
    listado.forEach(x => {
        if (x.LineMovsJson) {
            x.LineMovsJson.forEach(y => {
            if (bandera) {
                cuadro.push({
                No: idx,
                BUQUE: x.VesselName,
                VIAJE: x.Voyage,
                ATRAQUE: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                ZARPE: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                LINEA: y.ShippingLineCode,
                IMPORT: y.ImportTotal,  
                FULL: y.ImportFull,  
                EMPTY: y.ImportEmpty,   
                EXPORT: y.ExportTotal,  
                FULL_: y.ExportFull,  
                EMPTY_: y.ExportEmpty,
                FACTURA_No: '',
                FECHA_FACTURACION: '',
                BASE_IMPONIBLE: '',
                TOTAL_USD: '',
                BOLIPUERTOS: '',
                COMPANY: '',
                });
            } else {
                cuadro.push({
                No: '',
                BUQUE: '',
                VIAJE: '',
                ATRAQUE: '',
                ZARPE: '',
                LINEA: y.ShippingLineCode,
                IMPORT: y.ImportTotal,  
                FULL: y.ImportFull,  
                EMPTY: y.ImportEmpty,   
                EXPORT: y.ExportTotal,  
                FULL_: y.ExportFull,  
                EMPTY_: y.ExportEmpty,
                FACTURA_No: '',
                FECHA_FACTURACION: '',
                BASE_IMPONIBLE: '',
                TOTAL_USD: '',
                BOLIPUERTOS: '',
                COMPANY: '',
                });
            }
            bandera = false;
            });

            x.TotalMovsJson.forEach(y => {           
                cuadro.push({
                    No: 'TOTAL',
                    BUQUE: '',
                    VIAJE: '',
                    ATRAQUE: '',
                    ZARPE: '',
                    LINEA: x.LineMovsJson.length,
                    IMPORT: y.ImportTotal,  
                    FULL: y.ImportFull,  
                    EMPTY: y.ImportEmpty,   
                    EXPORT: y.ExportTotal,  
                    FULL_: y.ExportFull,  
                    EMPTY_: y.ExportEmpty,
                    FACTURA_No: '',
                    FECHA_FACTURACION: '',
                    BASE_IMPONIBLE: '',
                    TOTAL_USD: '',
                    BOLIPUERTOS: '',
                    COMPANY: '',
                });
            bandera = false;
            });
            
            if(x.LineMovsJson.length == 0 && x.TpVesselId == '2A719797-70DB-437B-B55D-58F4177CB87B'){
                cuadro.push({
                No: 'TOTAL',
                BUQUE: '',
                VIAJE: '',
                ATRAQUE: '',
                ZARPE: '',
                LINEA: 0,
                IMPORT: 0,  
                FULL: 0,  
                EMPTY: 0,   
                EXPORT: 0,  
                FULL_: 0,  
                EMPTY_: 0,
                FACTURA_No: '',
                FECHA_FACTURACION: '',
                BASE_IMPONIBLE: '',
                TOTAL_USD: '',
                BOLIPUERTOS: '',
                COMPANY: '',
                });
            }
        } else {
            cuadro.push({
                No: idx,
                BUQUE: x.VesselName,
                VIAJE: x.Voyage,
                ATRAQUE: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                ZARPE: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                LINEA: '',
                IMPORT: 0,  
                FULL: 0,  
                EMPTY: 0,   
                EXPORT: 0,  
                FULL_: 0,  
                EMPTY_: 0,
                FACTURA_No: '',
                FECHA_FACTURACION: '',
                BASE_IMPONIBLE: '',
                TOTAL_USD: '',
                BOLIPUERTOS: '',
                COMPANY: '',
            });

            cuadro.push({
            No: 'TOTAL',
            BUQUE: '',
            VIAJE: '',
            ATRAQUE: '',
            ZARPE: '',
            LINEA: 0,
            IMPORT: 0,  
            FULL: 0,  
            EMPTY: 0,   
            EXPORT: 0,  
            FULL_: 0,  
            EMPTY_: 0,
            FACTURA_No: '',
            FECHA_FACTURACION: '',
            BASE_IMPONIBLE: '',
            TOTAL_USD: '',
            BOLIPUERTOS: '',
            COMPANY: '',
            });
        }      
        
        if(cuadro.length > 0){ //para descartar los buques bulkcarrier hasta que indiquen el formato
            rowData.push(cuadro);
            idx++;
        }
        cuadro = [];
        arrtotales = [1,0,0,0,0,0,0];
        
        bandera=true;
    });
    //OJO DESACTIVAR ESTO, ES PARA VER 2 CUADROS
    //rowData.push(cuadro);

    if (rowData.length > 0){ 
        this.getVesselServedReportExcel(rowData,this.$t('label.vesselsServedIn')+' '+this.searchFilterDate.fromDay+' - '+this.searchFilterDate.toDay);
    } else {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailble'),
            type: 'error',
        });
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
  this.loadingOverlay = false;

}

async function getVesselServedReportExcel(rowData, titulo) {
    this.loadingOverlay = true;

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(this.$t('label.vesselsServed'));
  const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
  //INFO BUQUE

  let y = 0;
  let letra = 12;
  let columnsDefs = [];
  let keyColumns = [];
  let bandera = true;

  columnsDefs = Object.keys(rowData[0][0]);
  keyColumns = columnsDefs;
  y = 18;
  bandera = false;

  if (y < 10) letra = 8;
  worksheet.mergeCells('A1:B1');
  const response1 = await this.$http.getFile('base64', {
      path: this.user.LogoRoute.replace('Public/', '', null, 'i')
  });

  const divisiones1 = this.user.LogoRoute.split(".");
  const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
  const imageId = workbook.addImage({
      base64: myBase64Image,
      extension: divisiones1[1],
  });
  worksheet.addImage(imageId, 'A1:B1');
    worksheet.getCell("A1").border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
  const rowIndex1 = worksheet.getRow(1);
  rowIndex1.height = 100;

  worksheet.mergeCells("C1:" + numeroLetra(y) + "1");

  const customCell = worksheet.getCell("C1");
  const row = worksheet.getRow(1);
  row.height = 60;
  customCell.font = {
      name: "Calibri",
      family: 4,
      size: letra,
      underline: false,
      bold: true
  };
  customCell.note = {
      margins: {
          insetmode: 'auto',
          inset: [10, 10, 5, 5]
      }
  }
  customCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: 'f2f2f2'
      },
  }
  customCell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
  }
  customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }    
  customCell.value = titulo;

  const hoy = new Date().toLocaleString();
  worksheet.mergeCells("A2:I2");
  worksheet.mergeCells("J2:R2");
  const customCell2 = worksheet.getCell("A2");
  customCell2.height = 50;
  customCell2.font = {
      name: "Calibri",
      family: 4,
      size: letra,
      underline: false,
      bold: true
  };
  customCell2.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
  customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
  customCell2.value = this.$t('label.printed')+': '+hoy;
  
  const customCell4 = worksheet.getCell("J2");
  customCell4.height = 50;
  customCell4.font = {
      name: "Calibri",
      family: 4,
      size: letra,
      underline: false,
      bold: true
  };
  customCell4.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
    }
  customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
  customCell4.value = this.$t('label.user')+': ' + this.user.UserName + " " + this.user.UserLastName;
 // worksheet.mergeCells("A3:R3");

  rowData.forEach(x => {
    let i = 0;
    var headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
    headerRow.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };
    //TITULO COMBINADO
    let dataRow1 = worksheet.addRow();
    let cellA4 = dataRow1.getCell(i+1);
    let valAct = cellA4.address.slice(1);
    // worksheet.mergeCells("A"+valAct+":F"+valAct);
    worksheet.mergeCells("G"+valAct+":L"+valAct);
    cellA4 = worksheet.getCell("G"+valAct);
    
    cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    cellA4.font = {
        name: "Calibri",
        family: 4,
        underline: false,
        bold: true
    };
    cellA4.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
            argb: '808080'
        }
    }
    cellA4.font = {
        color: {
          argb: 'ffffff'
        }
    }
    cellA4.value = "CANTIDAD DE CONTENEDORES MOVILIZADOS (TDR)";
    cellA4.border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };

    //CUADRO 1
    i = 0;
    headerRow = worksheet.addRow();
    headerRow.font = {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        color: {
          argb: 'ffffff'
        }
    };
   /* headerRow.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };*/

    let inicio = headerRow._number+1; 
    let final= 0;
    let inicio2 = headerRow._number-1; 
    let final2= headerRow._number;

    worksheet.mergeCells("A"+inicio2+":A"+final2); 
    worksheet.mergeCells("B"+inicio2+":B"+final2); 
    worksheet.mergeCells("C"+inicio2+":C"+final2); 
    worksheet.mergeCells("D"+inicio2+":D"+final2); 
    worksheet.mergeCells("E"+inicio2+":E"+final2); 
    worksheet.mergeCells("F"+inicio2+":F"+final2);
    worksheet.mergeCells("M"+inicio2+":M"+final2); 
    worksheet.mergeCells("N"+inicio2+":N"+final2); 
    worksheet.mergeCells("O"+inicio2+":O"+final2); 
    worksheet.mergeCells("P"+inicio2+":P"+final2); 
    worksheet.mergeCells("Q"+inicio2+":Q"+final2); 
    worksheet.mergeCells("R"+inicio2+":R"+final2); 
//ws.getCell('A1').border
    worksheet.getCell("A"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("B"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("C"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("D"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("E"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("F"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("M"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("N"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("O"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("P"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("Q"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };
    worksheet.getCell("R"+inicio2).border = {  top: { style: 'thin' }, bottom: { style: 'thin' },  left: { style: 'thin' },  right: { style: 'thin' },  };

    worksheet.getCell("A"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("B"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("C"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("D"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("E"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("F"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("M"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("N"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("O"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("P"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("Q"+inicio2).font = { color: {  argb: 'ffffff' } };
    worksheet.getCell("R"+inicio2).font = { color: {  argb: 'ffffff' } };

    columnsDefs.map(function (data) {
      let valor = bandera ? data.headerName.length : data.length;
      let long = 10;
      if ((valor >= 5) && (valor <= 12)) {
          long = 20;
      } else if ((valor >= 11) && (valor <= 18)) {
          long = 25;
      } else if ((valor >= 19) && (valor <= 26)) {
          long = 30;
      } else if (valor >= 27) {
          long = 40;
      }

      worksheet.getColumn(i + 1).width = long;
      let cell = headerRow.getCell(i + 1);
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
              argb: '808080'
          }
      }
      cell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    }; 
      let valAct = cell.address.slice(1);
      cell.value = data.replace("_"," ");
      if (data=="BOLIPUERTOS") {
        cell.value = "PARTICIPACION ALIANZA \nBOLIPUERTOS POR \nFACTURACION (60%)";
        worksheet.getColumn(i + 1).width = 35;          
        worksheet.getRow(valAct).height = 50;
      } else if (data=="COMPANY") {
        cell.value = "PARTICIPACION ALIANZA \nOCKxd POR \nFACTURACION (40%)";
        worksheet.getColumn(i + 1).width = 35;
      }

      cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
      i++;
    })
    let b2 = 0;
    let btotal = false;
    x.map(async function (data) {
      let dataRow = worksheet.addRow();

      let indice = 0;
      keyColumns.map(function (data2) {
        let cell = dataRow.getCell(indice + 1);
        
        final = cell._row._number - 1; 
        
        /*let letra = cell.address[0];
        if (b2 < letrasMerge.length) {
          if(letrasMerge.indexOf(letra) !== -1){
            let valAct = cell.address.slice(1, -1);
            console.log(cell.address);
            console.log(letra+(valAct+x.length));
            //worksheet.mergeCells(cell.address+":"+letra+x.length);

          }
        }*/
        cell.value = data[data2];
        if (cell.value=="TOTAL") btotal = true;

        if (btotal) {
          cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                  argb: '808080'
              },
              color: {
                argb: 'ffffff'
              }
          }
          cell.font = {
              color: {
                argb: 'ffffff'
              }
          }
        }

        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        indice++;
      })
    })

    worksheet.mergeCells("A"+inicio+":A"+final);
    worksheet.mergeCells("B"+inicio+":B"+final);
    worksheet.mergeCells("C"+inicio+":C"+final);
    worksheet.mergeCells("D"+inicio+":D"+final);
    worksheet.mergeCells("E"+inicio+":E"+final);
    worksheet.mergeCells("M"+inicio+":R"+final);
    cellA4 = worksheet.getCell("M"+inicio);
    cellA4.value = "PARA SER LLENADO EN ADMON CENTRAL";
  });

  let fileName = documento;
  let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
  today = today.replaceAll("/", "");
  let time = new Date();
  time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
  //return false;
  workbook.xlsx.writeBuffer()
  .then(buffer => {
      saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
      this.$notify({
          group: 'container',
          text: this.$t('label.reportSuccessfully'),
          type: "success"
      });
  })
  this.loadingOverlay = false;

}

async function vesselsServedGeneralList() {
    this.loadingOverlay = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
    dateEnd = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

    let ReportJson = { DateStart: dateStart, DateEnd: dateEnd, CompanyBranchId: this.CompanyBranchId };

    await this.$http.post("VisitListByDateRangeManagerialReport", ReportJson, { root: 'ReportJson' })
        .then(response => {
            let listado = [...response.data.data];
            if (listado.length == 1 && Object.keys(listado[0]).length == 1) {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailble'),
                    type: 'error',
                });
            } else {             
                let bandera = true;
    
                let foundContainer = listado.find((item) => item.TpVesselId.toUpperCase() == '2A719797-70DB-437B-B55D-58F4177CB87B');
                let listadoContainer = foundContainer ? foundContainer.TpVesselVisitJson : [];
    
                let foundBulk = listado.find((item) => item.TpVesselId.toUpperCase() == process.env.VUE_APP_BULK_VESSEL_ID);
                let listadoBulk = foundBulk ? foundBulk.TpVesselVisitJson : [];
    
                let cuadroContainer = [], cuadroBulk = [];
                let rowDataContainer = [], rowDataBulk = [];
                let idx = 1;
    
                //Container:
                listadoContainer.forEach(x => {
                    cuadroContainer.push({
                        No: idx,
                        [`${this.$t('label.vessel')}`]: x.VesselName,
                        [`${this.$t('label.voyage')}`]: x.Voyage,
                        [`${this.$t('label.arrival')}`]: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                        [`${this.$t('label.departure')}`]: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                        TEUS: x.Teus,
                        FEUS: x.Feus,
                        TON: x.Ton,
                    });
    
                    cuadroContainer.push({
                        No: '***',
                        [`${this.$t('label.vessel')}`]: '',
                        [`${this.$t('label.voyage')}`]: '',
                        [`${this.$t('label.arrival')}`]: '',
                        [`${this.$t('label.departure')}`]: 'TOTAL',
                        TEUS: x.Teus,
                        FEUS: x.Feus,
                        TON: x.Ton,
                    });
    
                    if (cuadroContainer.length > 0) {
                        rowDataContainer.push(cuadroContainer);
                        idx++;
                    }
                    cuadroContainer = [];
                });

                idx = 1;
                //Bulk Carrier:
                listadoBulk.forEach(x => {
                    if (x.BlJson.length > 0) {
                        x.BlJson.forEach(y => {
                            if (bandera) {
                                cuadroBulk.push({
                                    No: idx,
                                    [`${this.$t('label.vessel')}`]: x.VesselName,
                                    [`${this.$t('label.voyage')}`]: x.Voyage,
                                    [`${this.$t('label.arrival')}`]: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                                    [`${this.$t('label.departure')}`]: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                                    BL: y.BlNro,
                                    TON: y.BlTonEmbarked,
                                });
                            } else {
                                cuadroBulk.push({
                                    No: '',
                                    [`${this.$t('label.vessel')}`]: '',
                                    [`${this.$t('label.voyage')}`]: '',
                                    [`${this.$t('label.arrival')}`]: '',
                                    [`${this.$t('label.departure')}`]: '',
                                    BL: y.BlNro,
                                    TON: y.BlTonEmbarked,
                                });
                            }
                            bandera = false;
                        });

                        if (x.BlJson.length == 0) {
                            cuadroBulk.push({
                                No: '***',
                                [`${this.$t('label.vessel')}`]: '',
                                [`${this.$t('label.voyage')}`]: '',
                                [`${this.$t('label.arrival')}`]: '',
                                [`${this.$t('label.departure')}`]: 'TOTAL',
                                BL: '',
                                TON: 0,
                            });
                        } else {
                            cuadroBulk.push({
                                No: '***',
                                [`${this.$t('label.vessel')}`]: '',
                                [`${this.$t('label.voyage')}`]: '',
                                [`${this.$t('label.arrival')}`]: '',
                                [`${this.$t('label.departure')}`]: 'TOTAL',
                                BL: '',
                                TON: x.BlJson.map((item) => item.BlTonEmbarked).reduce((anterior, actual) => anterior + actual, 0),
                            });
                            bandera = false;
                        }
                    } else {
                        cuadroBulk.push({
                            No: idx,
                            [`${this.$t('label.vessel')}`]: x.VesselName,
                            [`${this.$t('label.voyage')}`]: x.Voyage,
                            [`${this.$t('label.arrival')}`]: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                            [`${this.$t('label.departure')}`]: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                            BL: '',
                            TON: 0,
                        });

                        cuadroBulk.push({
                            No: '***',
                            [`${this.$t('label.vessel')}`]: '',
                            [`${this.$t('label.voyage')}`]: '',
                            [`${this.$t('label.arrival')}`]: '',
                            [`${this.$t('label.departure')}`]: '',
                            BL: 'TOTAL',
                            TON: 0,
                        });
                    }

                    if (cuadroBulk.length > 0) {
                        rowDataBulk.push(cuadroBulk);
                        idx++;
                    }
                    cuadroBulk = [];
                    bandera = true;
                });
    
                if (rowDataContainer.length > 0 || rowDataBulk.length > 0) {
                    this.getVesselGeneralServedReportExcel(rowDataContainer, rowDataBulk, this.$t('label.vesselsServedGeneralList') + ' ' + this.searchFilterDate.fromDay + ' - ' + this.searchFilterDate.toDay);
                } else {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: this.$t('label.noRecordsAvailble'),
                        type: 'error',
                    });
                }
            }
        }).catch(err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    this.loadingOverlay = false;

}
async function getVesselGeneralServedReportExcel(rowDataContainer, rowDataBulk, titulo) {
    this.loadingOverlay = true;

    const workbook = new Excel.Workbook();
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    if(rowDataContainer.length > 0){
        // Container Ship
        const worksheet = workbook.addWorksheet('CONTAINER SHIP');
    
        //INFO BUQUE
        let y = 0;
        let letra = 12;
        let columnsDefs = [];
        let keyColumns = [];
        let bandera = true;
    
        columnsDefs = Object.keys(rowDataContainer[0][0]);
        keyColumns = columnsDefs;
        y = 8;
        bandera = false;
    
        if (y < 8) letra = 8;
        worksheet.mergeCells('A1:B1');
        const response1 = await this.$http.getFile('base64', {
            path: this.user.LogoRoute.replace('Public/', '', null, 'i')
        });
    
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B1');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;
    
        worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
    
        const customCell = worksheet.getCell("C1");
        const row = worksheet.getRow(1);
        row.height = 60;
        customCell.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            },
        }
        customCell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell.value = titulo;
    
        const hoy = new Date().toLocaleString();
        worksheet.mergeCells("A2:D2");
        worksheet.mergeCells("E2:H2");
        const customCell2 = worksheet.getCell("A2");
        customCell2.height = 50;
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = this.$t('label.printed') + ': ' + hoy;
    
        const customCell4 = worksheet.getCell("E2");
        customCell4.height = 50;
        customCell4.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell4.value = this.$t('label.user') + ': ' + this.user.UserName + " " + this.user.UserLastName;
        // worksheet.mergeCells("A3:R3");
        rowDataContainer.forEach((x, index) => {
            if(index == 0) {
                var spaceRow = worksheet.addRow();
                spaceRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ffff'
                    },
                }
            }

            let i = 0;
            var headerRow = worksheet.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                color: {
                    argb: 'ffffff'
                }
            };
    
            let inicio = headerRow._number + 1;
            let final = 0;
            let inicio2 = headerRow._number;
            let final2 = headerRow._number;
    
            if(index == 0){
                headerRow.height = 50;     
                worksheet.mergeCells("A" + inicio2 + ":A" + inicio2);
                worksheet.mergeCells("B" + inicio2 + ":B" + inicio2);
                worksheet.mergeCells("C" + inicio2 + ":C" + inicio2);
                worksheet.mergeCells("D" + inicio2 + ":D" + inicio2);
                worksheet.mergeCells("E" + inicio2 + ":E" + inicio2);
                worksheet.mergeCells("F" + inicio2 + ":F" + inicio2);
                worksheet.mergeCells("G" + inicio2 + ":G" + inicio2);
                worksheet.mergeCells("H" + inicio2 + ":H" + inicio2);
    
                worksheet.getCell("A" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("B" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("C" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("D" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("E" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("F" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("G" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("H" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        
                worksheet.getCell("A" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("B" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("C" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("D" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("E" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("F" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("G" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("H" + inicio2).font = { color: { argb: 'ffffff' } };
        
                columnsDefs.map(function (data) {
                    let valor = bandera ? data.headerName.length : data.length;
                    let long = 10;
                    if ((valor >= 5) && (valor <= 12)) {
                        long = 20;
                    } else if ((valor >= 11) && (valor <= 18)) {
                        long = 25;
                    } else if ((valor >= 19) && (valor <= 26)) {
                        long = 30;
                    } else if (valor >= 27) {
                        long = 40;
                    }
        
                    if(data == 'TON' || data == 'TEUS' || data == 'FEUS'){
                        worksheet.getColumn(i + 1).width = 20;
                        let cell = headerRow.getCell(i + 1);
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            }
                        }
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                        cell.value = data.replace("_", " ");
            
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                        i++;
                    }else {
                        worksheet.getColumn(i + 1).width = long;
        
                        let cell = headerRow.getCell(i + 1);
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            }
                        }
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                        cell.value = data.replaceAll("_", " ");
            
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                        i++;
                    }
                })
            }

            let btotal = false;
            x.map(async function (data) {
                let dataRow = worksheet.addRow();
                let indice = 0;

                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);
    
                    final = cell._row._number - 1;
                    if(data2 == 'TON' || data2 == 'TEUS' || data2 == 'FEUS'){
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                        cell.value = data[data2];
                    }else
                        cell.value = data[data2] == '***'? '' : data[data2];

                    if (data[data2] == "***") btotal = true;
    
                    if (btotal) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            },
                            color: {
                                argb: 'ffffff'
                            }
                        }
                        cell.font = {
                            color: {
                                argb: 'ffffff'
                            }
                        }
                    }else 
                        dataRow.height = 70;
    
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    if(data2 == 'TON' || data2 == 'TEUS' || data2 == 'FEUS' || btotal){
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }else{
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }
                    indice++;
                })
            })
            worksheet.mergeCells("A" + inicio + ":A" + final);
            worksheet.mergeCells("B" + inicio + ":B" + final);
            worksheet.mergeCells("C" + inicio + ":C" + final);
            worksheet.mergeCells("D" + inicio + ":D" + final);
            worksheet.mergeCells("E" + inicio + ":E" + final);
            worksheet.mergeCells("F" + inicio + ":F" + final);
            worksheet.mergeCells("G" + inicio + ":G" + final);
            worksheet.mergeCells("H" + inicio + ":H" + final);
        });

        // Totals table
        let spaceRow = worksheet.addRow();
        spaceRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ffff'
            },
        }
        let totalRow = worksheet.addRow();
        let totalRowNumber = totalRow._number;
        worksheet.mergeCells("F" + totalRowNumber + ":F" + totalRowNumber);
        worksheet.mergeCells("G" + totalRowNumber + ":G" + totalRowNumber);
        worksheet.mergeCells("H" + totalRowNumber + ":H" + totalRowNumber);
        worksheet.getCell("F" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("G" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("H" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("F" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("G" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("H" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("F" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            },
        };
        worksheet.getCell("G" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            }
        };
        worksheet.getCell("H" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            }
        };
        worksheet.getCell("F" + totalRowNumber).value = 'TOTAL TEUS:';
        worksheet.getCell("G" + totalRowNumber).value = 'TOTAL FEUS:';
        worksheet.getCell("H" + totalRowNumber).value = 'TOTAL TON:';
        worksheet.getCell("F" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("G" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("H" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };

        let resultRow = worksheet.addRow();
        let resultRowNumber = resultRow._number;
        worksheet.mergeCells("F" + resultRowNumber + ":F" + resultRowNumber);
        worksheet.mergeCells("G" + resultRowNumber + ":G" + resultRowNumber);
        worksheet.mergeCells("H" + resultRowNumber + ":H" + resultRowNumber);
        worksheet.getCell("F" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("G" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("H" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        let totalTeus = rowDataContainer.map((item) => item[0].TEUS).reduce((anterior, actual) => anterior + actual, 0);
        let totalFeus = rowDataContainer.map((item) => item[0].FEUS).reduce((anterior, actual) => anterior + actual, 0);
        let totalTon = rowDataContainer.map((item) => item[0].TON).reduce((anterior, actual) => anterior + actual, 0);
        worksheet.getCell("F" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("F" + resultRowNumber).value = totalTeus;
        worksheet.getCell("G" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("G" + resultRowNumber).value = totalFeus;
        worksheet.getCell("H" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("H" + resultRowNumber).value = totalTon;
        worksheet.getCell("F" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("G" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("H" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
    }

    if (rowDataBulk.length > 0) {
        const worksheet = workbook.addWorksheet('BULK CARRIER');
        //INFO BUQUE

        let y = 0;
        let letra = 12;
        let columnsDefs = [];
        let keyColumns = [];
        let bandera = true;

        columnsDefs = Object.keys(rowDataBulk[0][0]);
        keyColumns = columnsDefs;
        y = 7;
        bandera = false;

        if (y < 7) letra = 8;
        worksheet.mergeCells('A1:B1');
        const response1 = await this.$http.getFile('base64', {
            path: this.user.LogoRoute.replace('Public/', '', null, 'i')
        });

        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B1');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        worksheet.mergeCells("C1:" + numeroLetra(y) + "1");

        const customCell = worksheet.getCell("C1");
        const row = worksheet.getRow(1);
        row.height = 60;
        customCell.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            },
        }
        customCell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell.value = titulo;

        const hoy = new Date().toLocaleString();
        worksheet.mergeCells("A2:D2");
        worksheet.mergeCells("E2:G2");
        const customCell2 = worksheet.getCell("A2");
        customCell2.height = 50;
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = this.$t('label.printed') + ': ' + hoy;

        const customCell4 = worksheet.getCell("E2");
        customCell4.height = 50;
        customCell4.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell4.value = this.$t('label.user') + ': ' + this.user.UserName + " " + this.user.UserLastName;
        // worksheet.mergeCells("A3:R3");

        rowDataBulk.forEach((x, index) => {
            if(index == 0) {
                var spaceRow = worksheet.addRow();
                spaceRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ffff'
                    },
                }
            }

            let i = 0;
            var headerRow = worksheet.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                color: {
                    argb: 'ffffff'
                }
            };

            let inicio = headerRow._number + 1;
            let final = 0;
            let inicio2 = headerRow._number;
            let final2 = headerRow._number;

            if(index == 0){
                headerRow.height = 50;
                worksheet.mergeCells("A" + inicio2 + ":A" + inicio2);
                worksheet.mergeCells("B" + inicio2 + ":B" + inicio2);
                worksheet.mergeCells("C" + inicio2 + ":C" + inicio2);
                worksheet.mergeCells("D" + inicio2 + ":D" + inicio2);
                worksheet.mergeCells("E" + inicio2 + ":E" + inicio2);
                worksheet.mergeCells("F" + inicio2 + ":F" + inicio2);
                worksheet.mergeCells("G" + inicio2 + ":G" + inicio2);
                //ws.getCell('A1').border
                worksheet.getCell("A" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("B" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("C" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("D" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("E" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("F" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("G" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
    
    
                worksheet.getCell("A" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("B" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("C" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("D" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("E" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("F" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("G" + inicio2).font = { color: { argb: 'ffffff' } };
    
                columnsDefs.map(function (data) {
                    let valor = bandera ? data.headerName.length : data.length;
                    let long = 10;
                    if ((valor >= 5) && (valor <= 12)) {
                        long = 20;
                    } else if ((valor >= 11) && (valor <= 18)) {
                        long = 25;
                    } else if ((valor >= 19) && (valor <= 26)) {
                        long = 30;
                    } else if (valor >= 27) {
                        long = 40;
                    }
    
                    worksheet.getColumn(i + 1).width = long;
                    let cell = headerRow.getCell(i + 1);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: '808080'
                        }
                    }
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.value = data.replace("_", " ");
                    if(data == 'BL' || data == 'TON'){
                        worksheet.getColumn(i + 1).width = 20;
                    }
                    if(data == 'TON'){
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    } else {
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }
                    i++;
                })
            }

            let btotal = false;
            x.map(async function (data) {
                let dataRow = worksheet.addRow();

                let indice = 0;
                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);

                    final = cell._row._number - 1;
                    if(data2 == 'TON'){
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                        cell.value = data[data2];
                    }else
                        cell.value = data[data2] == '***'? '' : data[data2];

                    if (data[data2] == "***") btotal = true;

                    if (btotal) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            },
                            color: {
                                argb: 'ffffff'
                            }
                        }
                        cell.font = {
                            color: {
                                argb: 'ffffff'
                            }
                        }
                    } 

                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    if(data2 == 'TON' || btotal){
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }else{
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }
                    indice++;
                })
            })

            worksheet.mergeCells("A" + inicio + ":A" + final);
            worksheet.mergeCells("B" + inicio + ":B" + final);
            worksheet.mergeCells("C" + inicio + ":C" + final);
            worksheet.mergeCells("D" + inicio + ":D" + final);
            worksheet.mergeCells("E" + inicio + ":E" + final);
            worksheet.mergeCells("G" + inicio + ":G" + final);
        });

        // Totals table
        let spaceRow = worksheet.addRow();
        spaceRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ffff'
            },
        }
        let totalRow = worksheet.addRow();
        let totalRowNumber = totalRow._number;
        worksheet.mergeCells("G" + totalRowNumber + ":G" + totalRowNumber);
        worksheet.getCell("G" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("G" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("G" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            }
        };
        worksheet.getCell("G" + totalRowNumber).value = 'TOTAL '+this.$t('label.mobilizedTon')+':';
        worksheet.getCell("G" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };

        let resultRow = worksheet.addRow();
        let resultRowNumber = resultRow._number;
        worksheet.mergeCells("G" + resultRowNumber + ":G" + resultRowNumber);
        worksheet.getCell("G" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        let totalTon = rowDataBulk.map((item) => {
            let found = item.find((row) => row.No == '***');
            if(found)
                return found.TON;
            else return 0;
        }).reduce((anterior, actual) => anterior + actual, 0);
        worksheet.getCell("G" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("G" + resultRowNumber).value = totalTon;
        worksheet.getCell("G" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
    }

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
        .then(buffer => {
            saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
            this.$notify({
                group: 'container',
                text: this.$t('label.reportSuccessfully'),
                type: "success"
            });
        })
    this.loadingOverlay = false;

}

async function visitsByVesselList() {
    this.loadingOverlay = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
    dateEnd = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

    let ReportJson = { VesselId: this.vesselId, DateStart: dateStart, DateEnd: dateEnd };

    await this.$http.post("VisitListByVesselManagerialReport", ReportJson, { root: 'ReportJson' })
        .then(response => {
            let listado = [...response.data.data];
            if (listado.length == 1 && Object.keys(listado[0]).length == 1) {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailble'),
                    type: 'error',
                });
            } else {             
                let bandera = true;
    
                let cuadro = [];
                let rowData = [];
                let idx = 1;

                let found = this.vessels.find((item) => item.VesselId == this.vesselId);
                let vesselType = found ? found.TpVesselName : '';
    
                //Container:
                if(vesselType === 'CONTAINER SHIP') {
                    listado.forEach(x => {
                        cuadro.push({
                            No: idx,
                            [`${this.$t('label.voyage')}`]: x.Voyage,
                            [`${this.$t('label.arrival')}`]: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                            [`${this.$t('label.departure')}`]: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                            [`${this.$t('label.TimeInDock')}`]: x.TimeInBerth,
                            [`${this.$t('label.beginningOperationDate')}`]: x.BeginOpsDate ? DateFormater.formatDateTimeWithSlash(x.BeginOpsDate) : '',
                            [`${this.$t('label.endOperationDate')}`]: x.EndOpsDate ? DateFormater.formatDateTimeWithSlash(x.EndOpsDate) : '',
                            [`${this.$t('label.timeOperations')}`]: x.OperationsTime,
                            [`${this.$t('label.MovementsPerHourNet')}`]: x.NetMovsXHour,
                            TEUS: x.Teus,
                            FEUS: x.Feus,
                            TON: x.Ton,
                        });

                        cuadro.push({
                            No: '***',
                            [`${this.$t('label.voyage')}`]: '',
                            [`${this.$t('label.arrival')}`]: '',
                            [`${this.$t('label.departure')}`]: '',
                            [`${this.$t('label.TimeInDock')}`]: '',
                            [`${this.$t('label.beginningOperationDate')}`]: '',
                            [`${this.$t('label.endOperationDate')}`]: '',
                            [`${this.$t('label.timeOperations')}`]: '',
                            [`${this.$t('label.MovementsPerHourNet')}`]: 'TOTAL',
                            TEUS: x.Teus,
                            FEUS: x.Feus,
                            TON: x.Ton,
                        });

                        if (cuadro.length > 0) {
                            rowData.push(cuadro);
                            idx++;
                        }
                        cuadro = [];
                    });
                }

                idx = 1;
                cuadro = [];
                bandera = true;
                //Bulk Carrier:
                if(vesselType === 'BULK CARRIER') {
                    listado.forEach(x => {
                        if (x.BlJson.length > 0) {
                            x.BlJson.forEach(y => {
                                if (bandera) {
                                    cuadro.push({
                                        No: idx,
                                        [`${this.$t('label.voyage')}`]: x.Voyage,
                                        [`${this.$t('label.arrival')}`]: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                                        [`${this.$t('label.departure')}`]: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                                        [`${this.$t('label.TimeInDock')}`]: x.TimeInBerth,
                                        [`${this.$t('label.beginningOperationDate')}`]: x.BeginOpsDate ? DateFormater.formatDateTimeWithSlash(x.BeginOpsDate) : '',
                                        [`${this.$t('label.endOperationDate')}`]: x.EndOpsDate ? DateFormater.formatDateTimeWithSlash(x.EndOpsDate) : '',
                                        [`${this.$t('label.timeOperations')}`]: x.OperationsTime,
                                        [`${this.$t('label.MovementsPerHourNet')}`]: x.NetMovsXHour,
                                        BL: y.BlNro,
                                        TON: y.BlTonEmbarked,
                                    });
                                } else {
                                    cuadro.push({
                                        No: '',
                                        [`${this.$t('label.voyage')}`]: '',
                                        [`${this.$t('label.arrival')}`]: '',
                                        [`${this.$t('label.departure')}`]: '',
                                        [`${this.$t('label.TimeInDock')}`]: '',
                                        [`${this.$t('label.beginningOperationDate')}`]: '',
                                        [`${this.$t('label.endOperationDate')}`]: '',
                                        [`${this.$t('label.timeOperations')}`]: '',
                                        [`${this.$t('label.MovementsPerHourNet')}`]: '',
                                        BL: y.BlNro,
                                        TON: y.BlTonEmbarked,
                                    });
                                }
                                bandera = false;
                            });

                            if (x.BlJson.length == 0) {
                                cuadro.push({
                                    No: '***',
                                    [`${this.$t('label.voyage')}`]: '',
                                    [`${this.$t('label.arrival')}`]: '',
                                    [`${this.$t('label.departure')}`]: '',
                                    [`${this.$t('label.TimeInDock')}`]: '',
                                    [`${this.$t('label.beginningOperationDate')}`]: '',
                                    [`${this.$t('label.endOperationDate')}`]: '',
                                    [`${this.$t('label.timeOperations')}`]: '',
                                    [`${this.$t('label.MovementsPerHourNet')}`]: 'TOTAL',
                                    BL: '',
                                    TON: 0,
                                });
                            } else {
                                cuadro.push({
                                    No: '***',
                                    [`${this.$t('label.voyage')}`]: '',
                                    [`${this.$t('label.arrival')}`]: '',
                                    [`${this.$t('label.departure')}`]: '',
                                    [`${this.$t('label.TimeInDock')}`]: '',
                                    [`${this.$t('label.beginningOperationDate')}`]: '',
                                    [`${this.$t('label.endOperationDate')}`]: '',
                                    [`${this.$t('label.timeOperations')}`]: '',
                                    [`${this.$t('label.MovementsPerHourNet')}`]: 'TOTAL',
                                    BL: '',
                                    TON: x.BlJson.map((item) => item.BlTonEmbarked).reduce((anterior, actual) => anterior + actual, 0),
                                });
                                bandera = false;
                            }
                        } else {
                            cuadro.push({
                                No: idx,
                                [`${this.$t('label.voyage')}`]: x.Voyage,
                                [`${this.$t('label.arrival')}`]: x.Ata ? DateFormater.formatDateTimeWithSlash(x.Ata) : '',
                                [`${this.$t('label.departure')}`]: x.Atd ? DateFormater.formatDateTimeWithSlash(x.Atd) : '',
                                [`${this.$t('label.TimeInDock')}`]: x.TimeInBerth,
                                [`${this.$t('label.beginningOperationDate')}`]: x.BeginOpsDate ? DateFormater.formatDateTimeWithSlash(x.BeginOpsDate) : '',
                                [`${this.$t('label.endOperationDate')}`]: x.EndOpsDate ? DateFormater.formatDateTimeWithSlash(x.EndOpsDate) : '',
                                [`${this.$t('label.timeOperations')}`]: x.OperationsTime,
                                [`${this.$t('label.MovementsPerHourNet')}`]: x.NetMovsXHour,
                                BL: '',
                                TON: 0,
                            });

                            cuadro.push({
                                No: '***',
                                [`${this.$t('label.voyage')}`]: '',
                                [`${this.$t('label.arrival')}`]: '',
                                [`${this.$t('label.departure')}`]: '',
                                [`${this.$t('label.TimeInDock')}`]: '',
                                [`${this.$t('label.beginningOperationDate')}`]: '',
                                [`${this.$t('label.endOperationDate')}`]: '',
                                [`${this.$t('label.timeOperations')}`]: '',
                                [`${this.$t('label.MovementsPerHourNet')}`]: '',
                                BL: 'TOTAL',
                                TON: 0,
                            });
                        }

                        if (cuadro.length > 0) {
                            rowData.push(cuadro);
                            idx++;
                        }
                        cuadro = [];
                        bandera = true;
                    });
                }
                
                if (rowData.length > 0) {
                    let titulo = `${this.$t('label.visitList')}: ${found.VesselName} ${found.Imo} ${this.$t('label.from')} ${this.searchFilterDate.fromDay} ${this.$t('label.to')} ${this.searchFilterDate.toDay}`;
                    this.getVisitsByVesselListReportExcel(rowData, vesselType, titulo);
                } else {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: this.$t('label.noRecordsAvailble'),
                        type: 'error',
                    });
                }
                
            }
        }).catch(err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    this.loadingOverlay = false;

}

function getYardVisitList() {
    if (this.yardId!='') {
        this.loadingOverlay = true;
        this.$http.get('YardVisitList-by-Yard', { YardId: this.yardId,  CompanyBranchId: this.CompanyBranchId })
            .then(response => {
                this.VisitList = response.data.data ?? [];
                this.loadingOverlay = false;
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            this.loadingOverlay = false;
            })
    }else{
        this.VisitList = [];
        this.loadingOverlay = false;
    }
}




function getYardVisitListInventary() {
    this.loadingOverlay = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay) ?? '';
    dateEnd = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay) ?? '';      

    let ReportJson = {
        "YardId": this.yardId ?? '',//Id del Patio 
        "VisitId": this.visitId ?? '', 
        "CompanyBranchId": this.branch.CompanyBranchId,
        "DateStart": dateStart != 'Invalid date' ? dateStart : '',
        "DateEnd": dateEnd != 'Invalid date' ? dateEnd : ''
    };

    this.$http.post('YardCargoGeneralReport',ReportJson, { root: 'ReportJson'})
        .then(response => {
            this.VisitName = this.VisitList;
            this.YardName = this.yards;
            let List = response.data.data;
            this.formatedItems = Object.entries(List[0]).length > 1 ? List : [];
            if (List.length == 1 && Object.keys(List[0]).length == 1) {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailble'),
                    type: 'error',
                });
            }else{
                if(this.formatedItems.length !== 0) {
                    this.loadingOverlay = true;
                    let currentDate = new Date();
                    let Filter = []; 
                    let rowDataFormated = [];


                    let tableHeader = {
                        label: [
                            '#',
                            this.$t('label.cargoGroup'),
                            `${this.$t('label.container')}/${this.$t('label.serial')}`,
                            this.$t('label.yard'),
                            this.$t('label.type'),
                            this.$t('label.transaction'),
                            this.$t('label.bl'),
                            this.$t('label.visit'),
                            'ATA',
                            this.$t('label.isoCode'),
                            this.$t('label.Mode'),
                            this.$t('label.Seals'),
                            this.$t('label.inventoryContains'),
                            this.$t('label.imdg'),
                            `${this.$t('label.weight')}(TON)`,
                            `${this.$t('label.volume')}(M³)`,
                            this.$t('label.temperature'),
                            `${this.$t('label.connected')}?`,
                            this.$t('label.DimTop'),
                            this.$t('label.DimLeft'),
                            this.$t('label.DimRight'),
                            this.$t('label.DimFront'),
                            this.$t('label.DimBack'),
                            this.$t('label.packaging'),
                            this.$t('label.heading'),
                            this.$t('label.customBroker'),
                            this.$t('label.govermentPrivate'),
                            this.$t('label.Procedence'),
                            this.$t('label.newShippingLine'),
                            this.$t('label.CurrentPosition'),
                            this.$t('label.CurrentArea'),
                            this.$t('label.daysInYard'),
                            this.$t('label.customsRegime'),
                            this.$t('label.endOperations'),
                            this.$t('label.dateEndOperations')
                        ],
                        key: [
                            'Nro', 
                            'GpoCargoName',
                            'ContainerCode',
                            'YardName',
                            'TpCargoDetailCode',
                            'TpTransacEirAcronym',
                            'BlNro',
                            'VisitDs',
                            'Ata',
                            'IsoCode',
                            'TpCargoConditionCode',
                            'Seals',
                            'DescriptionOfGoods',
                            'ImdgCode',
                            'WeigthTon',
                            'VolumenM3',
                            'Temperature',
                            'FgPlugged',
                            'DimTop',
                            'DimLeft',
                            'DimRight',
                            'DimFront',
                            'DimBack',
                            this.$i18n.locale=='en' ? 'PackagingNameEn'  : 'PackagingNameEs',
                            this.$i18n.locale=='en' ? 'HeadingNameEn'  : 'HeadingNameEs',
                            'CustomBrokerName',
                            'FgGovernment',
                            'Procedence',
                            'ShippingLineName',
                            'CurrentPosition',
                            'AreaJson',
                            'DaysInYard',
                            this.$i18n.locale=='en' ? 'CustomOperationDsEn'  : 'CustomOperationDsEs',
                            this.$i18n.locale=='en' ? 'LastOperationNameEn'  : 'LastOperationNameEs',
                            'LastOperationDate'
                        ],
                    };
                    
                 
                    let visitName = this?.VisitName.find(e => e.VisitId === this.visitId) ? this?.VisitName.find(e => e.VisitId === this.visitId): 'N/A';
        
                    Filter[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
                    Filter[1] = this.$t('label.visit')+': '+(visitName?.VisitId ? visitName?.VesselName+'-'+visitName?.VoyageArrival +' - ATA: '+ visitName?.Ata : 'N/A');
                    Filter[2] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
                    Filter[3] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

                    rowDataFormated = this.formatedItems.map(item => {
                        return {
                            ...item,
                            Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : '',
                            ReceptionDate : item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : '',
                            LastOperationDate : item.LastOperationDate ? DateFormater.formatDateTimeWithSlash(item.LastOperationDate) : '',
                            FgPlugged: item.FgPlugged==0 ? this.$t('label.no') : this.$t('label.yes'),
                            FgGovernment: item.FgGovernment==0 ? this.$t('label.no') : this.$t('label.yes'),
                            CurrentPosition: item.CurrentPositionJson.map(Position => Position.SlotCode.trim()).join(', '),
                            AreaJson: item.AreaJson.map(Area => Area.YardAreaName?.trim()).join(', ') ?? '',
                            DescriptionOfGoods: item.DescriptionOfGoods ? removeTags(item.DescriptionOfGoods) : '',
                        }
                    })
                    
                let yardName = this?.YardName.find(e => e.YardId === this.yardId) ? this?.YardName.find(e => e.YardId === this.yardId) : '';

                    this.getReporExcel(rowDataFormated, `${this.$t('label.inventoryList')}`, true, Filter, yardName.YardName , false, tableHeader);
                    this.loadingOverlay = false;
                }else{
                    this.$notify({  
                        group: 'container',
                        title: '¡Error!',
                        text: this.$t('label.noRecordsAvailbleReport'),
                        type: "error" 
                    });
                }
                
        
            }
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
}

function getYardEventList() {
    this.loadingOverlay = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay) ?? '';
    dateEnd = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay) ?? '';      

    let ReportJson = {
        "YardId": this.yardId ?? '',//Id del Patio 
        "CompanyBranchId": this.branch.CompanyBranchId,
        "DateStart": dateStart != 'Invalid date' ? dateStart : '',
        "DateEnd": dateEnd != 'Invalid date' ? dateEnd : ''
    };

    this.$http.post('YardCargoEventDetailReport',ReportJson, { root: 'ReportJson'})
        .then(response => {
            this.YardName = this.yards;
            let List = response.data.data;
            this.formatedItems = Object.entries(List[0]).length > 1 ? List : [];
            if (List.length == 1 && Object.keys(List[0]).length == 1) {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailble'),
                    type: 'error',
                });
            }else{
                if(this.formatedItems.length !== 0) {
                    this.loadingOverlay = true;
                    let currentDate = new Date();
                    let Filter = []; 
                    let rowDataFormated = [];


                    let tableHeader = {
                        label: [
                            '#',
                            `${this.$t('label.container')}/${this.$t('label.serial')}`,
                            this.$t('label.type'),
                            this.$t('label.transaction'),
                            this.$t('label.visit'),
                            'ATA',
                            this.$t('label.isoCode'),
                            this.$t('label.status'),
                            this.$t('label.Seals'),
                            this.$t('label.newShippingLine'),
                            this.$t('label.event'),
                            this.$t('label.startDate'),
                            this.$t('label.endDate'),
                            this.$t('label.totalTime'),
                            this.$t('label.machine'),
                            this.$t('label.previousPosition'),
                            this.$t('label.newPosition'),
                            this.$t('label.device'),
                            this.$t('label.user'),
                            this.$t('label.registerDate')                           
                        ],
                        key: [
                            'Nro', 
                            'ContainerCode',
                            'TpCargoDetailCode',
                            'TpTransacEirAcronym',
                            'VisitDs',
                            'Ata',
                            'IsoCode',
                            'TpCargoStatusName',
                            'Seals',
                            'ShippingLineName',
                            this.$i18n.locale=='en' ? 'EventNameEn'  : 'EventNameEs',
                            'TransactionBegin',
                            'TransactionFinish',
                            'TotalTimeH',
                            'MachineName',
                            'previousPosition',
                            'NewPosition',
                            'DeviceCod',
                            'TransaLogin',
                            'TransaRegDate'
                        ],
                    };
                    
                 
                    Filter[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
                    Filter[1] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
                    Filter[2] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

                    rowDataFormated = this.formatedItems.map(item => {
                        return {
                            ...item,
                            Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : '',
                            TransaRegDate : item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                            NewPosition: item.YardNewDetailPosJson.map(Position => Position.SlotCode.trim()).join(', '),
                            previousPosition: item.YardPreviousDetailPosJson.map(Position => Position.SlotCode.trim()).join(', '),
                        }
                    })
                    
                let yardName = this?.YardName.find(e => e.YardId === this.yardId) ? this?.YardName.find(e => e.YardId === this.yardId) : '';

                    this.getReporExcel(rowDataFormated, `${this.$t('label.EventList')}`, true, Filter, yardName.YardName , false, tableHeader);
                    this.loadingOverlay = false;
                }else{
                    this.$notify({  
                        group: 'container',
                        title: '¡Error!',
                        text: this.$t('label.noRecordsAvailbleReport'),
                        type: "error" 
                    });
                }
                
        
            }
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
}

function getYardInpectionList() {
    this.loadingOverlay = true;

    let dateStart = '';
    let dateEnd = '';

    dateStart = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay) ?? '';
    dateEnd = DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay) ?? '';      

    let ReportJson = {
        "YardId": this.yardId ?? '',//Id del Patio 
        "VisitId": this.visitId ?? '', 
        "CompanyBranchId": this.branch.CompanyBranchId,
        "DateStart": dateStart != 'Invalid date' ? dateStart : '',
        "DateEnd": dateEnd != 'Invalid date' ? dateEnd : ''
    };

    this.$http.post('YardCargoInspectionsGeneralReport',ReportJson, { root: 'ReportJson'})
        .then(response => {
            this.VisitName = this.VisitList;
            this.YardName = this.yards;
            let List = response.data.data;
            this.formatedItems = Object.entries(List[0]).length > 1 ? List : [];
            if (List.length == 1 && Object.keys(List[0]).length == 1) {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailble'),
                    type: 'error',
                });
            }else{
                if(this.formatedItems.length !== 0) {
                    this.loadingOverlay = true;
                    let currentDate = new Date();
                    let Filter = []; 
                    let rowDataFormated = [];


                    let tableHeader = {
                        label: [
                            '#',
                            `${this.$t('label.container')}/${this.$t('label.serial')}`,
                            this.$t('label.type'),
                            this.$t('label.visit'),
                            'ATA',
                            this.$t('label.inspectionType'),
                            this.$t('label.isoCode'),
                            this.$t('label.status'),
                            this.$t('label.newShippingLine'),
                            this.$t('label.event'),
                            this.$t('label.inspectionDate'),
                            this.$t('label.device'),
                            this.$t('label.user'),
                            this.$t('label.registerDate')                           
                        ],
                        key: [
                            'Nro', 
                            'ContainerCode',
                            'TpCargoDetailCode',
                            'VisitDs',
                            'Ata',
                            this.$i18n.locale=='en' ? 'TpInspectionNameEn'  : 'TpInspectionNameEs',
                            'IsoCode',  
                            'TpCargoStatusName',                            
                            'ShippingLineCode', 
                            this.$i18n.locale=='en' ? 'EventNameEN'  : 'EventNameEs',
                            'TransactionDate',
                            'DeviceCode',
                            'TransaLogin',
                            'TransaRegDate'
                        ],
                    };
                    
                 
                    let visitName = this?.VisitName.find(e => e.VisitId === this.visitId) ? this?.VisitName.find(e => e.VisitId === this.visitId): 'N/A';
                    
                    Filter[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
                    Filter[1] = this.$t('label.visit')+': '+(visitName?.VisitId ? visitName?.VesselName+'-'+visitName?.VoyageArrival +' - ATA: '+ visitName?.Ata : 'N/A');
                    Filter[2] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
                    Filter[3] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

                    rowDataFormated = this.formatedItems.map(item => {
                        return {
                            ...item,
                            Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : '',
                            TransaRegDate : item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                            TransaRegDate : item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                        }
                    })
                    
                let yardName = this?.YardName.find(e => e.YardId === this.yardId) ? this?.YardName.find(e => e.YardId === this.yardId) : '';

                    this.getReporExcel(rowDataFormated, `${this.$t('label.InspectionsList')}`, true, Filter, yardName.YardName , false, tableHeader);
                    this.loadingOverlay = false;
                }else{
                    this.$notify({  
                        group: 'container',
                        title: '¡Error!',
                        text: this.$t('label.noRecordsAvailbleReport'),
                        type: "error" 
                    });
                }
                
        
            }
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
}

function listVisitList(event){
    this.yardId = event.target.value;
    this.getYardVisitList();
}

async function getVisitsByVesselListReportExcel(rowData, vesselType, titulo) {
    this.loadingOverlay = true;

    const workbook = new Excel.Workbook();
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    if(vesselType == 'CONTAINER SHIP'){
        // Container Ship
        const worksheet = workbook.addWorksheet('CONTAINER SHIP');
    
        //INFO BUQUE
        let y = 0;
        let letra = 12;
        let columnsDefs = [];
        let keyColumns = [];
        let bandera = true;
    
        columnsDefs = Object.keys(rowData[0][0]);
        keyColumns = columnsDefs;
        y = 12;
        bandera = false;
    
        if (y < 12) letra = 8;
        worksheet.mergeCells('A1:B1');
        const response1 = await this.$http.getFile('base64', {
            path: this.user.LogoRoute.replace('Public/', '', null, 'i')
        });
    
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B1');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;
    
        worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
    
        const customCell = worksheet.getCell("C1");
        const row = worksheet.getRow(1);
        row.height = 60;
        customCell.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            },
        }
        customCell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell.value = titulo;
    
        const hoy = new Date().toLocaleString();
        worksheet.mergeCells("A2:E2");
        worksheet.mergeCells("F2:L2");
        const customCell2 = worksheet.getCell("A2");
        customCell2.height = 50;
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = this.$t('label.printed') + ': ' + hoy;
    
        const customCell4 = worksheet.getCell("F2");
        customCell4.height = 50;
        customCell4.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell4.value = this.$t('label.user') + ': ' + this.user.UserName + " " + this.user.UserLastName;
        // worksheet.mergeCells("A3:R3");
    
        rowData.forEach((x, index) => {
            if(index == 0) {
                var spaceRow = worksheet.addRow();
                spaceRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ffff'
                    },
                }
            }

            let i = 0;
            var headerRow = worksheet.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                color: {
                    argb: 'ffffff'
                }
            };
    
            let inicio = headerRow._number + 1;
            let final = 0;
            let inicio2 = headerRow._number;
            let final2 = headerRow._number;
    
            if(index == 0){
                headerRow.height = 50;
                worksheet.mergeCells("A" + inicio2 + ":A" + inicio2);
                worksheet.mergeCells("B" + inicio2 + ":B" + inicio2);
                worksheet.mergeCells("C" + inicio2 + ":C" + inicio2);
                worksheet.mergeCells("D" + inicio2 + ":D" + inicio2);
                worksheet.mergeCells("E" + inicio2 + ":E" + inicio2);
                worksheet.mergeCells("F" + inicio2 + ":F" + inicio2);
                worksheet.mergeCells("G" + inicio2 + ":G" + inicio2);
                worksheet.mergeCells("H" + inicio2 + ":H" + inicio2);
                worksheet.mergeCells("I" + inicio2 + ":I" + inicio2);
                worksheet.mergeCells("J" + inicio2 + ":J" + inicio2);
                worksheet.mergeCells("K" + inicio2 + ":K" + inicio2);
                worksheet.mergeCells("L" + inicio2 + ":L" + inicio2);
    
                worksheet.getCell("A" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("B" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("C" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("D" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("E" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("F" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("G" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("H" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("I" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("J" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("K" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("L" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        
                worksheet.getCell("A" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("B" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("C" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("D" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("E" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("F" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("G" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("H" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("I" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("J" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("K" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("L" + inicio2).font = { color: { argb: 'ffffff' } };
        
                columnsDefs.map(function (data) {
                    let valor = bandera ? data.headerName.length : data.length;
                    let long = 10;
                    if ((valor >= 5) && (valor <= 12)) {
                        long = 20;
                    } else if ((valor >= 11) && (valor <= 18)) {
                        long = 25;
                    } else if ((valor >= 19) && (valor <= 26)) {
                        long = 30;
                    } else if (valor >= 27) {
                        long = 40;
                    }
        
                    if(data == 'TON' || data == 'TEUS' || data == 'FEUS'){
                        worksheet.getColumn(i + 1).width = 20;
                        let cell = headerRow.getCell(i + 1);
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            }
                        }
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                        cell.value = data.replace("_", " ");
            
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                        i++;
                    }else {
                        worksheet.getColumn(i + 1).width = long;
        
                        let cell = headerRow.getCell(i + 1);
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            }
                        }
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                        cell.value = data.replaceAll("_", " ");
            
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                        i++;
                    }
                })
            }

            let btotal = false;
            x.map(async function (data) {
                let dataRow = worksheet.addRow();
                let indice = 0;

                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);
    
                    final = cell._row._number - 1;
                    if(data2 == 'TON' || data2 == 'TEUS' || data2 == 'FEUS'){
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                        cell.value = data[data2];
                    }else
                        cell.value = data[data2] == '***'? '' : data[data2];

                    if (data[data2] == "***") btotal = true;
    
                    if (btotal) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            },
                            color: {
                                argb: 'ffffff'
                            }
                        }
                        cell.font = {
                            color: {
                                argb: 'ffffff'
                            }
                        }
                    }
    
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    if(data2 == 'TON' || data2 == 'TEUS' || data2 == 'FEUS' || btotal){
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }else{
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }
                    indice++;
                })
            })
            worksheet.mergeCells("A" + inicio + ":A" + final);
            worksheet.mergeCells("B" + inicio + ":B" + final);
            worksheet.mergeCells("C" + inicio + ":C" + final);
            worksheet.mergeCells("D" + inicio + ":D" + final);
            worksheet.mergeCells("E" + inicio + ":E" + final);
            worksheet.mergeCells("F" + inicio + ":F" + final);
            worksheet.mergeCells("G" + inicio + ":G" + final);
            worksheet.mergeCells("H" + inicio + ":H" + final);
            worksheet.mergeCells("I" + inicio + ":I" + final);
            worksheet.mergeCells("J" + inicio + ":J" + final);
            worksheet.mergeCells("K" + inicio + ":K" + final);
            worksheet.mergeCells("L" + inicio + ":L" + final);
        });

        // Totals table
        let spaceRow = worksheet.addRow();
        spaceRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ffff'
            },
        }
        let totalRow = worksheet.addRow();
        let totalRowNumber = totalRow._number;
        worksheet.mergeCells("J" + totalRowNumber + ":J" + totalRowNumber);
        worksheet.mergeCells("K" + totalRowNumber + ":K" + totalRowNumber);
        worksheet.mergeCells("L" + totalRowNumber + ":L" + totalRowNumber);
        worksheet.getCell("J" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("K" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("L" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("J" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("K" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("L" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("J" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            },
        };
        worksheet.getCell("K" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            }
        };
        worksheet.getCell("L" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            }
        };
        worksheet.getCell("J" + totalRowNumber).value = 'TOTAL TEUS:';
        worksheet.getCell("K" + totalRowNumber).value = 'TOTAL FEUS:';
        worksheet.getCell("L" + totalRowNumber).value = 'TOTAL TON:';
        worksheet.getCell("J" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("K" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("L" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };

        let resultRow = worksheet.addRow();
        let resultRowNumber = resultRow._number;
        worksheet.mergeCells("J" + resultRowNumber + ":J" + resultRowNumber);
        worksheet.mergeCells("K" + resultRowNumber + ":K" + resultRowNumber);
        worksheet.mergeCells("L" + resultRowNumber + ":L" + resultRowNumber);
        worksheet.getCell("J" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("K" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("L" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        let totalTeus = rowData.map((item) => item[0].TEUS).reduce((anterior, actual) => anterior + actual, 0);
        let totalFeus = rowData.map((item) => item[0].FEUS).reduce((anterior, actual) => anterior + actual, 0);
        let totalTon = rowData.map((item) => item[0].TON).reduce((anterior, actual) => anterior + actual, 0);
        worksheet.getCell("J" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("J" + resultRowNumber).value = totalTeus;
        worksheet.getCell("K" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("K" + resultRowNumber).value = totalFeus;
        worksheet.getCell("L" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("L" + resultRowNumber).value = totalTon;
        worksheet.getCell("J" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("K" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
        worksheet.getCell("L" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
    }

    if (vesselType == 'BULK CARRIER') {
        const worksheet = workbook.addWorksheet('BULK CARRIER');
        //INFO BUQUE

        let y = 0;
        let letra = 12;
        let columnsDefs = [];
        let keyColumns = [];
        let bandera = true;

        columnsDefs = Object.keys(rowData[0][0]);
        keyColumns = columnsDefs;
        y = 11;
        bandera = false;

        if (y < 11) letra = 8;
        worksheet.mergeCells('A1:B1');
        const response1 = await this.$http.getFile('base64', {
            path: this.user.LogoRoute.replace('Public/', '', null, 'i')
        });

        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B1');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        worksheet.mergeCells("C1:" + numeroLetra(y) + "1");

        const customCell = worksheet.getCell("C1");
        const row = worksheet.getRow(1);
        row.height = 60;
        customCell.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            },
        }
        customCell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell.value = titulo;

        const hoy = new Date().toLocaleString();
        worksheet.mergeCells("A2:E2");
        worksheet.mergeCells("F2:K2");
        const customCell2 = worksheet.getCell("A2");
        customCell2.height = 50;
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = this.$t('label.printed') + ': ' + hoy;

        const customCell4 = worksheet.getCell("F2");
        customCell4.height = 50;
        customCell4.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell4.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell4.value = this.$t('label.user') + ': ' + this.user.UserName + " " + this.user.UserLastName;
        // worksheet.mergeCells("A3:R3");

        rowData.forEach((x, index) => {
            if(index == 0) {
                var spaceRow = worksheet.addRow();
                spaceRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ffff'
                    },
                }
            }

            let i = 0;
            var headerRow = worksheet.addRow();
            headerRow.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                color: {
                    argb: 'ffffff'
                }
            };

            let inicio = headerRow._number + 1;
            let final = 0;
            let inicio2 = headerRow._number;
            let final2 = headerRow._number;

            if(index == 0){
                headerRow.height = 50;
                worksheet.mergeCells("A" + inicio2 + ":A" + inicio2);
                worksheet.mergeCells("B" + inicio2 + ":B" + inicio2);
                worksheet.mergeCells("C" + inicio2 + ":C" + inicio2);
                worksheet.mergeCells("D" + inicio2 + ":D" + inicio2);
                worksheet.mergeCells("E" + inicio2 + ":E" + inicio2);
                worksheet.mergeCells("F" + inicio2 + ":F" + inicio2);
                worksheet.mergeCells("G" + inicio2 + ":G" + inicio2);
                worksheet.mergeCells("H" + inicio2 + ":H" + inicio2);
                worksheet.mergeCells("I" + inicio2 + ":I" + inicio2);
                worksheet.mergeCells("J" + inicio2 + ":J" + inicio2);
                worksheet.mergeCells("K" + inicio2 + ":K" + inicio2);
                //ws.getCell('A1').border
                worksheet.getCell("A" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("B" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("C" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("D" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("E" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("F" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("G" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("H" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("I" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("J" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
                worksheet.getCell("K" + inicio2).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
    
    
                worksheet.getCell("A" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("B" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("C" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("D" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("E" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("F" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("G" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("H" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("I" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("J" + inicio2).font = { color: { argb: 'ffffff' } };
                worksheet.getCell("K" + inicio2).font = { color: { argb: 'ffffff' } };
    
                columnsDefs.map(function (data) {
                    let valor = bandera ? data.headerName.length : data.length;
                    let long = 10;
                    if ((valor >= 5) && (valor <= 12)) {
                        long = 20;
                    } else if ((valor >= 11) && (valor <= 18)) {
                        long = 25;
                    } else if ((valor >= 19) && (valor <= 26)) {
                        long = 30;
                    } else if (valor >= 27) {
                        long = 40;
                    }
    
                    worksheet.getColumn(i + 1).width = long;
                    let cell = headerRow.getCell(i + 1);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: '808080'
                        }
                    }
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.value = data.replace("_", " ");
                    if(data == 'BL' || data == 'TON'){
                        worksheet.getColumn(i + 1).width = 20;
                    }
                    if(data == 'TON'){
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    } else {
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }
                    i++;
                })
            }

            let btotal = false;
            x.map(async function (data) {
                let dataRow = worksheet.addRow();

                let indice = 0;
                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);

                    final = cell._row._number - 1;
                    if(data2 == 'TON'){
                        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                        cell.value = data[data2];
                    }else
                        cell.value = data[data2] == '***'? '' : data[data2];

                    if (data[data2] == "***") btotal = true;

                    if (btotal) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '808080'
                            },
                            color: {
                                argb: 'ffffff'
                            }
                        }
                        cell.font = {
                            color: {
                                argb: 'ffffff'
                            }
                        }
                    } 

                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    if(data2 == 'TON' || btotal){
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }else{
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    }
                    indice++;
                })
            })

            worksheet.mergeCells("A" + inicio + ":A" + final);
            worksheet.mergeCells("B" + inicio + ":B" + final);
            worksheet.mergeCells("C" + inicio + ":C" + final);
            worksheet.mergeCells("D" + inicio + ":D" + final);
            worksheet.mergeCells("E" + inicio + ":E" + final);
            worksheet.mergeCells("G" + inicio + ":G" + final);
            worksheet.mergeCells("H" + inicio + ":H" + final);
            worksheet.mergeCells("I" + inicio + ":I" + final);
            worksheet.mergeCells("J" + inicio + ":J" + final);
            worksheet.mergeCells("K" + inicio + ":K" + final);
        });

        // Totals table
        let spaceRow = worksheet.addRow();
        spaceRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ffff'
            },
        }
        let totalRow = worksheet.addRow();
        let totalRowNumber = totalRow._number;
        worksheet.mergeCells("K" + totalRowNumber + ":K" + totalRowNumber);
        worksheet.getCell("K" + totalRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        worksheet.getCell("K" + totalRowNumber).font = { color: { argb: 'ffffff' } };
        worksheet.getCell("K" + totalRowNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '808080'
            }
        };
        worksheet.getCell("K" + totalRowNumber).value = 'TOTAL '+this.$t('label.mobilizedTon')+':';
        worksheet.getCell("K" + totalRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };

        let resultRow = worksheet.addRow();
        let resultRowNumber = resultRow._number;
        worksheet.mergeCells("K" + resultRowNumber + ":K" + resultRowNumber);
        worksheet.getCell("K" + resultRowNumber).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, };
        let totalTon = rowData.map((item) => {
            let found = item.find((row) => row.No == '***');
            if(found)
                return found.TON;
            else return 0;
        }).reduce((anterior, actual) => anterior + actual, 0);
        worksheet.getCell("K" + resultRowNumber).numFmt = '#,##0.00;[Red]\-#,##0.00';
        worksheet.getCell("K" + resultRowNumber).value = totalTon;
        worksheet.getCell("K" + resultRowNumber).alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };
    }

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/", "");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    workbook.xlsx.writeBuffer()
        .then(buffer => {
            saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
            this.$notify({
                group: 'container',
                text: this.$t('label.reportSuccessfully'),
                type: "success"
            });
        })
    this.loadingOverlay = false;

}

function getReportType(value){
    this.nameReport = (value == 1) ? this.$t('label.strategicAlliance') : this.$t('label.vesselsServed') ; 

    if(value == 1){
    this.nameReport = this.$t('label.strategicAlliance'); 

    }else if(value == 2){
    this.nameReport = this.$t('label.vesselsServed') ; 

    } else if(value == 3){
    this.nameReport = this.$t('label.vesselsServedGeneralList') ; 

    } else if(value == 4){
    this.nameReport = this.$t('label.visitsByVessel') ;
    this.getVesselOptions();

    }else if(value == 5){
    this.yards = this.branch?.YardJson;
    this.yardId = '';
    this.nameReport = this.$t('label.inventoriesOfContainersInThePort') ;
    this.yardId ? this.getYardVisitList() : '';

    }else if(value == 6){
    this.yards = this.branch?.YardJson;
    this.yardId = '';
    this.nameReport = this.$t('label.eventGeneralList') ;

    }
    else if(value == 7){
    this.yards = this.branch?.YardJson;
    this.yardId = '';
    this.nameReport = this.$t('label.inspectionGeneralList') ;
    this.yardId ? this.getYardVisitList() : '';

    }

    this.reportType = value;
}
function getNameReport(){
    if(this.reportType == 1){
        return this.$t('label.strategicAlliance'); 

    }else if(this.reportType == 2){
        return this.$t('label.vesselsServed') ; 

    } else if(this.reportType == 3){
        return this.$t('label.vesselsServedGeneralList'); 

    } else if(this.reportType == 4){
        return this.$t('label.visitsByVessel') ;

    }else if(this.reportType == 5){
        return this.$t('label.inventoriesOfContainersInThePort') ;

    }else if(this.reportType == 6){
        return this.$t('label.eventGeneralList') ;

    }else if(this.reportType == 7){
        return this.$t('label.inspectionGeneralList') ;
        
    }
    else {
        return '';
    }
}

function formatHourMMHH(e) {
    e.preventDefault();
    return false;
}

function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
}

function refreshComponent(){
    this.items = [];
    this.nameReport = '';
    this.reportType = 0;
    this.rangeDate = [];
    this.searchFilterDate.fromDay="";
    this.searchFilterDate.toDay="";
    let currentDate = new Date();
    this.laterDate = currentDate;
    this.previousDate =  new Date("2010-01-01");
    this.vesselId = '';
    this.vessels = [];
    this.Visit="";
    this.VisitName = {};
    this.yardId = ''
}

function exportExcel(){
    if((this.reportType != 5 && this.reportType != 6 && this.reportType != 7) && (this.searchFilterDate.fromDay == '' || this.searchFilterDate.toDay == '')){
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailble'),
            type: 'error',
        });
        return;
    }
    if(this.reportType == 1){
        this.alianza();
    }
    else if(this.reportType == 2){
        this.atendidos();
    }else if(this.reportType == 3){
        this.vesselsServedGeneralList();
    }else if(this.reportType == 4 &&  this.vesselId != ''){
        this.visitsByVesselList();
    }else if(this.reportType == 5 ){
        this.getYardVisitListInventary();
    }
    else if(this.reportType == 6 ){
        this.getYardEventList();
    }
    else if(this.reportType == 7 ){
        this.getYardInpectionList();
    }
    else{
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailble'),
            type: 'error',
        });
    }
}

function getVesselOptions(){
    this.loadingVessels = true;
    this.$http
        .get("VesselWithVisit-by-CompanyBranchId", {
            CompanyBranchId: this.branch ? this.branch.CompanyBranchId : "",
        }).then((response) => {
            this.vessels = response.data.data;
        }).catch((err) => {
            this.$notify({
                group: "container",
                title: "¡Error!",
                text: err,
                type: "error",
            });
        })
        .then(() => {
            this.loadingVessels = false;
        });
}


function vesselOptions(){
  if(this.vessels.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.vessels.map(function(e){
      chart.push({
        label: `${e.VesselName} (${e.Imo}) - ${e.TpVesselName}`,
        value: e.VesselId,
      })    
      })
      return chart;
  }
}

function visitOptions(){
  if(this.VisitList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.VisitList.map(function(e){
      chart.push({
        label: `${e.VesselName} (${e.VoyageArrival}) - ${e.Ata}`,
        value: e.VisitId,
      })    
      })
      return chart;
  }
}

function yardOptions(){
  if(this.yards.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.yards.map(function(e){
      chart.push({
        value: e.YardId,
        label: e.YardName
      })    
      })
      return chart;
  }
}

function isEmpty(arr) {
  return arr.length == 0;
}

function mounted(){
    let currentDate = new Date();
    this.laterDate = currentDate;
    this.previousDate =  new Date("2010-01-01");
}

export default {
    name:'report',
    directives: UpperCase,
    data,
    mixins: [mixinServicio, GeneralMixin, YardManagement],
    methods: {
        numeroLetra,
        atendidos,
        alianza,
        getVesselServedReportExcel,
        getStrategicAllianceReportExcel,
        getReportType,
        formatHourMMHH,
        validateDateRange,
        refreshComponent,
        exportExcel,
        vesselsServedGeneralList,
        getVesselGeneralServedReportExcel,
        isEmpty,
        getVesselOptions,
        visitsByVesselList,
        getYardVisitList,
        getVisitsByVesselListReportExcel,
        getYardVisitListInventary,
        getYardEventList,
        getYardInpectionList,
        listVisitList
    },  
    watch: {
    },
    computed: {
        vesselOptions,
        visitOptions,
        yardOptions,
        getNameReport,
        ...mapState({
            roleSelected: state => state.auth.role,
            user: (state) => state.auth.user,
            branch: (state) => state.auth.branch,
            CompanyBranchId: state => state.auth.branch.CompanyBranchId,
        })
    },
    mounted,
}
</script>

<style lang="scss" >
    @import 'css/style.scss';
    .sinpading{
        padding:0px;
    }
    #button-callendar-double{
        border-radius: 1px;
        border: 1px solid #d8dbe0;
    }

    .vue-datepicker-callendar-double {
        .mx-input {
            height: 32px;
            border-top-right-radius: 1px;
            border-bottom-right-radius: 1px;
        }
    }
</style>